
<template>
  <CRow>
    <CCol col="12" xl="12">
         <CCard class="cards">
        <CCardHeader>
          <CRow>
            <CCol class="styleHeader" col="6">
             {{$t('message.employeePayroll')}}
             <br/>
              <small>{{$t('message.fromDate') + ': ' + fromDate + ' - ' + $t('message.toDate') + ': ' + toDate}}</small>
            </CCol>

        </CRow>
        </CCardHeader>
        <CCardBody>
          <div class="row">
            <div class="col-md-3">
              <CFormText>
                  {{$t('message.fromDate')}}
                </CFormText>
                 <input
                  id="name"
                  v-model="fromDate"
                  pattern="\d{4}-\d{2}-\d{2}"
                  type="date"
                  class="filter"
                  :placeholder="$t('message.fromDate')"
                />
            </div>
            <div class="col-md-3">
              <CFormText>
                  {{$t('message.toDate')}}
                </CFormText>
                 <input
                  id="name"
                  v-model="toDate"
                  pattern="\d{4}-\d{2}-\d{2}"
                  type="date"
                  class="filter"
                  :placeholder=" $t('message.toDate')"
                />
            </div>
           <div class="col-md-2">
              <CButton  v-on:click="filter" class="p-2 mx-2 py-3 px-5 sub" square color="primary">
                   <CIcon class="c-icon" name="cil-chevron-top" /> &emsp;  {{$t('message.filter')}}
                </CButton>
            </div>
          </div>
          <hr/>
          <!-- Using the VdtnetTable component -->
          <vdtnet-table
            ref="table"
            :fields="fields"
            :opts="options"
            @show="doAlertShow"
            >
          </vdtnet-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import VdtnetTable from '../VdtnetTable.vue'
// import VdtnetTable from '../VdtnetTable.vue'
import $ from 'jquery'
const date = new Date()
let fromDate = (date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear())
let toDate = (date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear())
export default {
  name: 'InventoryReports',
  components: { VdtnetTable },
  data () {
    return {
      fromDate: fromDate,
      toDate: toDate,
      typeOfCustomers: [],
      customers: [],
      selectedTypeOfCustomer: '',
      options: {
        ajax: {
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + localStorage.token
            )
          },
          url: this.$hostUrl + 'report/employees/payroll',
          data: function (d) {
            d.start_date = fromDate
            d.end_date = toDate
          },
          dataSrc: (json) => {
            return json.data
          }
        },
        buttons: [
          {
            extend: 'print',
            text: this.$t('message.print'),
            titleAttr: '',
            className: 'print',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          },
          {
            extend: 'copy',
            text: this.$t('message.copy'),
            className: 'copy',
            titleAttr: ''
          },
          {
            extend: 'excel',
            text: '',
            titleAttr: 'إكسل',
            className: 'fa fa-table btn-success',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          }
        ],
        dom:
          "<'row'<'col-sm-6 col-md-6'f><'col-sm-6 col-md-6 added'l>>" +
          "<'row'<'col-sm-12'Btr>>" +
          "<'row'<'col-sm-12 col-md-5'p><'col-sm-12 col-md-7'i>>",
        paging: true,
        language: {
          url: this.$t('message.url')
        },
        responsive: false,
        processing: true,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        ordering: true,
        lengthChange: true,
        serverSide: false,
        fixedHeader: true,
        saveState: true,
        select: {
          style: 'multi'
        }
      },
      fields: {
        name: {
          label: this.$t('message.employeeName'),
          sortable: true,
          searchable: true,
          defaultOrder: 'SORT_ASC '
        },
        job: {
          label: this.$t('message.jobName'),
          sortable: true,
          searchable: true,
          defaultOrder: 'SORT_ASC '
        },
        bank_account: {
          label: this.$t('message.bankAccount'),
          sortable: false,
          searchable: true
        },
        salary: {
          label: this.$t('message.salary'),
          sortable: true,
          searchable: true,
          defaultOrder: 'SORT_ASC '
        },
        balance: {
          label: this.$t('message.currentBalance'),
          sortable: true,
          searchable: true,
          defaultOrder: 'SORT_ASC '
        },
        total: {
          label: this.$t('message.total'),
          sortable: true,
          searchable: true,
          defaultOrder: 'SORT_ASC '
        },
        actions: {
          isLocal: true,
          label: this.$t('message.operations'),
          // defaultContent: <router-link class="btn btn-info add-btn btn-sm" to="{ path: '/customers/'+customer.id+'/show', params: {id:customer.id}}">تفاصيل الخدمة </router-link>
          defaultContent: '<button href="javascript:void(0);" data-action="show" class="btn btn-info btn-sm" title="عرض التفاصيل "><i class="mdi mdi-square-show-outline"></i>' + this.$t('message.showDetails') + ' </button>'
        }

      },
      quickSearch: '',
      details: {},
      total: [],
      startDate: null,
      endDate: null
    }
  },
  created () {
    const typeOfCustomers = []
    this.$http
      .get(`${this.$hostUrl}customer-type`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          typeOfCustomers.push({ id: value.id, name: value.name })
        })
        this.typeOfCustomers = typeOfCustomers
      })
  },
  methods: {
    doLoadTable (cb) {
      this.$http.getJSON(
        this.$hostUrl + 'report/employees/payroll',
        function (data) {
          cb(data.data)
        }
      )
    },
    filter ($event) {
      console.log($event)
      fromDate = this.fromDate
      toDate = this.toDate
      const table = this.$refs.table
      table.reload()
    },
    doAfterReload (data, table) {
      console.log('data reloaded')
    },
    doCreating (comp, el) {
      console.log('creating')
    },
    doCreated (comp) {
      console.log('created')
    },
    doAlertShow (data) {
      this.$router.push({ path: `/employees/${data.id}/show`, params: { id: data.id } })
    },
    doSearch () {
      this.$refs.table.search(this.quickSearch)
    },
    doExport (type) {
      const parms = this.$refs.table.getServerParams()
      parms.export = type
    },
    formatCode (zipcode) {
      return zipcode.split('-')[0]
    }
  }
}
</script>
<style scoped>

</style>
